const config = {
	API_URL: process.env.REACT_APP_API_URL ?? "https://api.dev.dp-cargo.com",
	MEDIA_BACKEND_URL:
		process.env.REACT_APP_MEDIA_BACKEND_URL ?? "https://media.dp-cargo.com",
	CUSTOMER_URL:
		process.env.REACT_APP_CUSTOMER_URL ??
		"https://customer.dev.dp-cargo.com",
};

export default config;
