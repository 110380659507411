import { BaseQueryFn } from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import { createApi } from "@reduxjs/toolkit/dist/query/react";
import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { getAccessToken } from "../utils/getAccessToken";
import config from "../config";
import { getWarehouseUser } from "../utils/getWarehouseUser";

export interface List<D> {
	data: {
		data: D[];
		paginationInfo: {
			total: number;
			page: number;
			limit: number;
		};
	};
	status_code: number;
	error_message: string;
}

export const axiosBaseQuery =
	(
		{ baseUrl }: { baseUrl: string } = { baseUrl: "" },
	): BaseQueryFn<AxiosRequestConfig, any, { data: any; status?: number }> =>
	async (configs) => {
		try {
			const newConfig = {
				...configs,
				headers: {
					Authorization: `Bearer ${getAccessToken()}`,
				},
				params: {
					...configs.params,
					warehouse_id: getWarehouseUser()
						? getWarehouseUser().id
						: null,
				},
			};
			const result = await axios(newConfig);
			return { data: result.data };
		} catch (axiosError) {
			const err = axiosError as AxiosError;
			return {
				error: {
					status: err.response?.status,
					data: err.response?.data,
				},
			};
		}
	};

const baseQuery = axiosBaseQuery({
	baseUrl: config.API_URL ?? "",
});

export const axiosClient = axios.create({
	baseURL: config.API_URL,
	headers: {
		"Content-Type": "application/json",
	},
});

axiosClient.interceptors.request.use(
	(config) => {
		const token = getAccessToken();
		if (token) {
			config.headers["Authorization"] = `Bearer ${token}`;
		}
		if (getWarehouseUser()) {
			config.params = {
				...config.params,
				warehouse_id: getWarehouseUser().id,
			};
		}
		return config;
	},
	(error) => {
		return Promise.reject(error);
	},
);

export const api = createApi({
	baseQuery: baseQuery,
	reducerPath: "api",
	tagTypes: [
		"Box",
		"Customer",
		"DeliverySlip",
		"Employee",
		"Mawb",
		"Order",
		"Report",
		"Role",
		"Setting",
		"Tracking",
		"Transaction",
		"Warehouse",
		"News",
		"Pages",
		"DeliveryUnits",
		"DeliveryOrder",
	],
	endpoints: () => ({}),
});
