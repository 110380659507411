import axios from "axios";
import { api } from ".";
import { createAction } from "@reduxjs/toolkit";
import { getAccessToken } from "../utils/getAccessToken";

export const successToastAction = createAction<string>("toast/success");
export const failedToastAction = createAction<string>("toast/failed");

export const LoginRequest = async (username: string, password: string) => {
	const response = await axios.post("/api/auth/login", {
		email: username,
		password,
	});
	return response;
};

export const LogoutUser = async () => {
	const response = await axios({
		url: "/auth/logout",
		method: "POST",
		headers: { Authorization: `Bearer ${getAccessToken()}` },
	});
	return response;
};

const authApi = api.injectEndpoints({
	endpoints: (build) => ({
		resetPasswordRequest: build.mutation<any, { email: string }>({
			query: (data) => ({
				url: `api/auth/reset-password-request`,
				method: "POST",
				data,
			}),
		}),
		verifyResetPasswordToken: build.mutation<
			any,
			{ resetPasswordToken: string }
		>({
			query: (data) => ({
				url: `api/auth/verify-reset-password-token`,
				method: "POST",
				data,
			}),
		}),
		setNewPassword: build.mutation<
			any,
			{ newPassword: string; resetPasswordToken: string }
		>({
			query: (data) => ({
				url: `api/auth/set-new-password`,
				method: "POST",
				data,
			}),
		}),
	}),
});

export const {
	useResetPasswordRequestMutation,
	useSetNewPasswordMutation,
	useVerifyResetPasswordTokenMutation,
} = authApi;
