import { AxiosResponse } from "axios";
import { History } from "history";
import jwtDecode from "jwt-decode";
import { Cookies } from "react-cookie";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { LoginRequest } from "../../api/authApi";
import errorHandler from "../../utils/errorHandler";
import { setCredentials } from "../../utils/setCredentials";
import {
	initLoginUser,
	loginUserFailed,
	loginUserSuccess,
	logoutUser,
} from "./reducers";
/**
 * Sets the session
 * @param {*} user
 */

export const setSession = (key?: string, value?: any, httpOnly?: boolean) => {
	let cookies = new Cookies();
	if (key && value)
		cookies.set(key, value, {
			path: "/",
			sameSite: "lax",
			secure: false,
			httpOnly: httpOnly,
		});
	else {
		cookies.remove("refresh_token", { path: "/" });
		cookies.remove("access_token", { path: "/" });
		cookies.remove("user", { path: "/" });
		cookies.remove("rememberAccount", { path: "/" });
	}
};

/**
 * Login the user
 * @param {*} payload - username and password
 */

function* login(action: { payload: { email: string; password: string } }) {
	try {
		const authResponse: AxiosResponse = yield call(
			LoginRequest,
			action.payload.email,
			action.payload.password,
		);
		const { token, refreshToken } = authResponse.data;
		const tokenDecode: any = jwtDecode(token);
		if (
			authResponse.data.role.name === "Khách hàng" ||
			authResponse.data.role.tpe === "khach_hang"
		) {
			const message: any = "Tài khoản không hợp lệ!";
			yield put(loginUserFailed(message));
			setSession();
		} else {
			if (tokenDecode.warehouseVN) {
				localStorage.setItem(
					"warehouse_vn",
					JSON.stringify(tokenDecode.warehouseVN),
				);
				localStorage.setItem(
					"warehouse_org",
					JSON.stringify(tokenDecode.warehouseVN),
				);
			} else {
				localStorage.setItem(
					"warehouse_org",
					JSON.stringify({
						id: null,
						label: null,
					}),
				);
				localStorage.setItem(
					"warehouse_vn",
					JSON.stringify({
						id: null,
						label: null,
					}),
				);
			}

			setCredentials({
				access_token: token,
				refresh_token: refreshToken,
				user: tokenDecode,
				user_infor: {
					data: authResponse.data.data,
					role: authResponse.data.role,
				},
			});
			yield put(loginUserSuccess(jwtDecode(token)));

		}
	} catch (err) {
		const message = errorHandler(err);
		yield put(loginUserFailed(message));
		setSession();
	}
}

/**
 * Logout the user
 * @param {*} param0
 */
function* logout({ payload: { history } }: { payload: { history: History } }) {
	try {
		setSession();
		yield call(() => {
			window.location.replace("/auth/login");
		});
	} catch (error) {}
}

export function* watchLoginUser() {
	yield takeEvery(initLoginUser, login);
}

export function* watchLogoutUser() {
	yield takeEvery(logoutUser, logout);
}

function* authSaga() {
	yield all([fork(watchLoginUser), fork(watchLogoutUser)]);
}

export default authSaga;
