import { combineReducers } from "redux"

import Auth from "./auth/reducers"
import Layout from "./layout/reducers"
import Box from "./box/reducers"
import Delivery from "./deliveryBill/reducers"
import Transaction from "./transaction/reducers"
import { api } from "../api"

export default combineReducers({
  Auth,
  Layout,
  Box,
  Delivery,
  Transaction,
  [api.reducerPath]: api.reducer,
})
