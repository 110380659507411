import React from "react"
import { Route, RouteProps } from "react-router-dom"

// components
import PrivateRoute from "./PrivateRoute"

// root
const Root = React.lazy(() => import("./Root"))

// auth
const Login = React.lazy(() => import("../pages/auth/Login"))
const Logout = React.lazy(() => import("../pages/auth/Logout"))
const Confirm = React.lazy(() => import("../pages/auth/Confirm"))
const ForgetPassword = React.lazy(() => import("../pages/auth/ForgetPassword"))
const LockScreen = React.lazy(() => import("../pages/auth/LockScreen"))
const VerifyForgetPassword = React.lazy(
  () => import("../pages/auth/VerifyForgetPassword")
)
const NewPassword = React.lazy(() => import("../pages/auth/NewPassword"))

// dashboard
const Revenue = React.lazy(() => import("../pages/dashboard/Revenue"))
const Mining = React.lazy(() => import("../pages/dashboard/Mining"))

// apps
// awb
const AwbDetail = React.lazy(() => import("../pages/awb/Detail/index"))
const AwbList = React.lazy(() => import("../pages/awb/index"))
const AwbEdit = React.lazy(() => import("../pages/awb/Edit"))
const AwbSync = React.lazy(() => import("../pages/awb/Components/SyncAwb"))
// tracking
const TrackingDetail = React.lazy(
  () => import("../pages/tracking/Detail/index")
)
const TrackingList = React.lazy(() => import("../pages/tracking/index"))
const TrackingEdit = React.lazy(() => import("../pages/tracking/Edit"))
const TrackingSearch = React.lazy(
  () => import("../pages/tracking/SearchTracking")
)
const CreateOrderTracking = React.lazy(
  () => import("../pages/tracking/CreateOrder")
)

// DeliverySlip
const DeliverySlip = React.lazy(() => import("../pages/delivery-slip/index"))
const CreateVouchers = React.lazy(
  () => import("../pages/delivery-slip/CreateVouchers")
)
const CreateDeliverySlip = React.lazy(
  () => import("../pages/delivery-slip/CreateDeliverySlip")
)
const VoteConfirm = React.lazy(
  () => import("../pages/delivery-slip/VoteConfirm")
)
const PrintCoupons = React.lazy(
  () => import("../pages/delivery-slip/PrintCoupons")
)
const EditDeliverySlip = React.lazy(
  () => import("../pages/delivery-slip/EditDeliverySlip")
)

// delivery order
const DeliveryOrder = React.lazy(() => import("../pages/deliveryOrder"))

// order
const OrderDetail = React.lazy(() => import("../pages/order/Detail/index"))
const OrderList = React.lazy(() => import("../pages/order/index"))
const OrderEdit = React.lazy(() => import("../pages/order/Edit"))
// customer
const CustomerDetail = React.lazy(
  () => import("../pages/customers/Detail/index")
)
const CustomerList = React.lazy(() => import("../pages/customers/index"))
const CustomerEdit = React.lazy(() => import("../pages/customers/Edit"))
const CustomerCreate = React.lazy(
  () => import("../pages/customers/AddCustomer")
)
const InforUserLogged = React.lazy(
  () => import("../pages/customers/InforUserLogged")
)
// employee
const EmployeeDetail = React.lazy(() => import("../pages/employee/View"))
const EmployeeList = React.lazy(() => import("../pages/employee/index"))
const EmployeeEdit = React.lazy(() => import("../pages/employee/Edit"))
const EmployeeCreate = React.lazy(() => import("../pages/employee/AddEmployee"))
// transaction
const TransactionDetail = React.lazy(
  () => import("../pages/transaction/Detail/index")
)
const TransactionList = React.lazy(() => import("../pages/transaction/index"))
const TransactionEdit = React.lazy(() => import("../pages/transaction/Edit"))
// setting
const SettingBox = React.lazy(() => import("../pages/setting/SettingBox"))
const SettingPrice = React.lazy(() => import("../pages/setting/SettingPrice"))
const SettingList = React.lazy(() => import("../pages/setting"))
const SettingRole = React.lazy(() => import("../pages/setting/SettingRole"))
const SettingBankAccount = React.lazy(
  () => import("../pages/setting/SettingBankAccount")
)
const SettingDeliveryUnits = React.lazy(
  () => import("../pages/setting/SettingDeliveryUnits")
)
const SettingMailServer = React.lazy(
  () => import("../pages/setting/SettingMailServer")
)
const SettingCodePrefix = React.lazy(
  () => import("../pages/setting/SettingCodePrefix")
)
const SettingContact = React.lazy(
  () => import("../pages/setting/SettingContact")
)

// page config
const TemplatePrivate = React.lazy(
  () => import("../pages/configPage/templates/Template")
)
const TemplateCreate = React.lazy(
  () => import("../pages/configPage/templates/Components/CreatePage")
)
const Templates = React.lazy(() => import("../pages/configPage/templates"))
const NewsCustomer = React.lazy(() => import("../pages/configPage/news"))

// extra pages
const Error404 = React.lazy(() => import("../pages/error/Error404"))
const Error500 = React.lazy(() => import("../pages/error/Error500"))

export interface RoutesProps {
  path: RouteProps["path"]
  name?: string
  component?: any
  route?: any
  exact?: RouteProps["exact"]
  icon?: string
  header?: string
  roles?: string[]
  action?: string
  children?: RoutesProps[]
}

// root routes
const rootRoute: RoutesProps = {
  path: "/",
  exact: true,
  component: Root,
  route: Route,
}

// app
const projectAppRoutes: RoutesProps[] = [
  {
    path: "/mawb",
    name: "Awb",
    route: PrivateRoute,
    roles: ["Admin"],
    icon: "uil-briefcase",
    action: "api:: GET /api/awbs/list",
    children: [
      {
        path: "/mawb/sync",
        name: "Awb Sync",
        component: AwbSync,
        route: PrivateRoute,
        action: "api:: POST /api/awbs/sync",
      },
      {
        path: "/mawb/:id",
        name: "Awb Detail",
        component: AwbDetail,
        route: PrivateRoute,
        action: "api:: GET /api/awbs/:id",
      },
      {
        path: "/mawb",
        name: "Awb List",
        component: AwbList,
        route: PrivateRoute,
        action: "api:: GET /api/awbs/list",
      },
      {
        path: "/mawb/edit/:id",
        name: "Awb Edit",
        component: AwbEdit,
        route: PrivateRoute,
        action: "api:: PUT /api/awbs/update/:id",
      },
    ],
  },
  {
    path: "/tracking",
    name: "Tracking",
    route: PrivateRoute,
    roles: ["Admin"],
    icon: "uil-briefcase",
    action: "api:: GET /api/trackings",
    children: [
      {
        path: "/tracking/create-order/:id",
        name: "Tracking Detail",
        component: CreateOrderTracking,
        route: PrivateRoute,
        action: "api:: POST /api/trackings/:id/create-order",
      },
      {
        path: "/tracking/edit/:id",
        name: "Tracking Detail",
        component: TrackingEdit,
        route: PrivateRoute,
        action: "api:: PUT /api/trackings/:id",
      },
      {
        path: "/tracking/search",
        name: "Tracking Search",
        component: TrackingSearch,
        route: PrivateRoute,
        action: "api:: GET /api/trackings/find-from-warehouse",
      },
      {
        path: "/tracking/:id",
        name: "Tracking Detail",
        component: TrackingDetail,
        route: PrivateRoute,
        action: "api:: GET /api/trackings/:id",
      },
      {
        path: "/tracking",
        name: "Tracking List",
        component: TrackingList,
        route: PrivateRoute,
        action: "api:: GET /api/trackings",
      },
    ],
  },
  {
    path: "/deliveryslip",
    name: "Delivery-slip",
    route: PrivateRoute,
    roles: ["Admin", "ke_toan", "nhan_vien_khai_thac", "nhan_vien_kinh_doanh"],
    icon: "uil-briefcase",
    action: "api:: GET /api/delivery_bills",
    children: [
      {
        path: "/deliveryslip/print-coupons/:id",
        name: "PrintCoupons",
        component: PrintCoupons,
        route: PrivateRoute,
      },
      {
        path: "/deliveryslip/view-delivery-slip/:id",
        name: "EditDeliverySlip",
        component: EditDeliverySlip,
        route: PrivateRoute,
        action: "api:: GET /api/delivery_bills/:id",
      },
      {
        path: "/deliveryslip/print-coupons",
        name: "PrintCoupons",
        component: PrintCoupons,
        route: PrivateRoute,
      },
      {
        path: "/deliveryslip/vote-confirm/:bill_id",
        name: "VoteConfirm",
        component: VoteConfirm,
        route: PrivateRoute,
      },
      {
        path: "/deliveryslip/create-vouchers",
        name: "CreateVouchers",
        component: CreateVouchers,
        route: PrivateRoute,
        action: "api:: POST /api/delivery_bills",
      },
      {
        path: "/deliveryslip/create-delivery-slip/:id",
        name: "CreateDeliverySlip",
        component: CreateDeliverySlip,
        route: PrivateRoute,
        action: "api:: POST /api/delivery_bills",
      },
      {
        path: "/deliveryslip",
        name: "Delivery-slip List",
        component: DeliverySlip,
        route: PrivateRoute,
        action: "api:: GET /api/delivery_bills",
      },
    ],
  },
  {
    path: "/deliveryorder",
    name: "Delivery Order",
    route: PrivateRoute,
    roles: ["Admin"],
    icon: "shopping-cart",
    action: "api:: GET /api/vn_delivery_order",
    children: [
      {
        path: "/deliveryorder",
        name: "Delivery Order List",
        component: DeliveryOrder,
        route: PrivateRoute,
        action: "api:: GET /api/vn_delivery_orders",
      },
    ],
  },
  {
    path: "/order",
    name: "Order",
    route: PrivateRoute,
    roles: ["Admin"],
    icon: "uil-briefcase",
    action: "api:: GET /api/orders",
    children: [
      {
        path: "/order/edit/:id",
        name: "Order Edit Detail",
        component: OrderEdit,
        route: PrivateRoute,
        action: "api:: PUT /api/orders/:id",
      },
      {
        path: "/order/:id",
        name: "Order Detail",
        component: OrderDetail,
        route: PrivateRoute,
        action: "api:: GET /api/orders/:id",
      },
      {
        path: "/order",
        name: "Order List",
        component: OrderList,
        route: PrivateRoute,
        action: "api:: GET /api/orders",
      },
    ],
  },
  {
    path: "/customer",
    name: "Customer",
    route: PrivateRoute,
    roles: ["Admin"],
    icon: "uil-briefcase",
    action: "api:: GET /api/customers",
    children: [
      {
        path: "/customer/infor-user",
        name: "Infor User Logged",
        component: InforUserLogged,
        route: PrivateRoute,
      },
      {
        path: "/customer/create",
        name: "Customer Create",
        component: CustomerCreate,
        route: PrivateRoute,
        action: "api:: POST /api/customers",
      },
      {
        path: "/customer/edit/:id",
        name: "Customer Detail",
        component: CustomerEdit,
        route: PrivateRoute,
        action: "api:: PUT /api/customers/:id",
      },
      {
        path: "/customer/:id/:action",
        name: "Customer Detail",
        component: CustomerDetail,
        route: PrivateRoute,
        action: "api:: GET /api/customers/:id",
      },
      {
        path: "/customer",
        name: "Customer List",
        component: CustomerList,
        route: PrivateRoute,
        action: "api:: GET /api/customers",
      },
    ],
  },
  {
    path: "/employee",
    name: "Employee",
    route: PrivateRoute,
    roles: ["Admin"],
    icon: "uil-briefcase",
    action: "api:: GET /api/employees",
    children: [
      {
        path: "/employee/create",
        name: "Employee Create",
        component: EmployeeCreate,
        route: PrivateRoute,
        action: "api:: POST /api/employees",
      },
      {
        path: "/employee/edit/:id",
        name: "Employee Edit",
        component: EmployeeEdit,
        route: PrivateRoute,
        action: "api:: PUT /api/employees/:id",
      },
      {
        path: "/employee/:id",
        name: "Employee Detail",
        component: EmployeeDetail,
        route: PrivateRoute,
        action: "api:: GET /api/employees/:id",
      },
      {
        path: "/employee",
        name: "Employee List",
        component: EmployeeList,
        route: PrivateRoute,
        action: "api:: GET /api/employees",
      },
    ],
  },
  {
    path: "/transaction",
    name: "Transaction",
    route: PrivateRoute,
    roles: ["Admin"],
    icon: "uil-briefcase",
    action: "api:: GET /api/transactions",
    children: [
      {
        path: "/transaction",
        name: "Transaction List",
        component: TransactionList,
        route: PrivateRoute,
        action: "api:: GET /api/transactions",
      },
      {
        path: "/transaction/:id",
        name: "Transaction Detail",
        component: TransactionDetail,
        route: PrivateRoute,
        action: "api:: GET /api/transactions/:id",
      },
      {
        path: "/transaction/edit/:id",
        name: "Transaction Edit",
        component: TransactionEdit,
        route: PrivateRoute,
        action: "api:: PUT /api/transactions/:id",
      },
    ],
  },
  {
    path: "/settings",
    name: "Setting",
    route: PrivateRoute,
    roles: ["Admin"],
    icon: "uil-briefcase",
    action: "api:: POST /api/general_configs",
    children: [
      {
        path: "/settings/box-coefficient",
        name: "Setting Box",
        component: SettingBox,
        route: PrivateRoute,
        action: "api:: POST /api/general_configs",
      },
      {
        path: "/settings/price",
        name: "Setting Price",
        component: SettingPrice,
        route: PrivateRoute,
        action: "api:: POST /api/general_configs",
      },
      {
        path: "/settings/role",
        name: "Setting Role",
        component: SettingRole,
        route: PrivateRoute,
        action: "api:: POST /api/general_configs",
      },
      {
        path: "/settings/bank-accounts",
        name: "Setting Bank Account",
        component: SettingBankAccount,
        route: PrivateRoute,
        action: "api:: POST /api/general_configs",
      },
      {
        path: "/settings/delivery-units",
        name: "Setting Delivery Units",
        component: SettingDeliveryUnits,
        route: PrivateRoute,
        action: "api:: POST /api/general_configs",
      },
      {
        path: "/settings/mail-server",
        name: "Setting Mail Server",
        component: SettingMailServer,
        route: PrivateRoute,
        action: "api:: POST /api/general_configs",
      },
      {
        path: "/settings/contact-information",
        name: "Setting Contact",
        component: SettingContact,
        route: PrivateRoute,
        action: "api:: POST /api/general_configs",
      },
      {
        path: "/settings/code-prefix",
        name: "Setting Contact",
        component: SettingCodePrefix,
        route: PrivateRoute,
        action: "api:: POST /api/general_configs",
      },
      {
        path: "/settings",
        name: "Setting List",
        component: SettingList,
        route: PrivateRoute,
        action: "api:: POST /api/general_configs",
      },
    ],
  },
  {
    path: "/report",
    name: "Report",
    route: PrivateRoute,
    roles: ["Admin"],
    icon: "airplay",
    action: "api:: GET /api/report/revenue/warehouse",
    children: [
      {
        path: "/report/revenue",
        name: "Revenue",
        component: Revenue,
        route: PrivateRoute,
        action: "api:: GET /api/report/revenue/warehouse",
      },
      {
        path: "/report/mining",
        name: "Mining",
        component: Mining,
        route: PrivateRoute,
        action: "api:: GET /api/report/exploitation",
      },
    ],
  },
  {
    path: "/page-config",
    name: "Page Config",
    route: PrivateRoute,
    roles: ["Admin"],
    icon: "layers",
    children: [
      {
        path: "/page-config/news",
        name: "List News",
        component: NewsCustomer,
        route: PrivateRoute,
      },
      {
        path: "/page-config/create",
        name: "Page config",
        component: TemplateCreate,
        route: PrivateRoute,
      },
      {
        path: "/page-config/:id",
        name: "Page config",
        component: TemplatePrivate,
        route: PrivateRoute,
      },
      {
        path: "/page-config",
        name: "Templates",
        component: Templates,
        route: PrivateRoute,
      },
    ],
  },
]

// auth
const authRoutes: RoutesProps[] = [
  {
    path: "/auth/login",
    exact: true,
    name: "Login",
    component: Login,
    route: Route,
  },
  {
    path: "/auth/confirm",
    name: "Confirm",
    component: Confirm,
    route: Route,
  },
  {
    path: "/auth/forget-password",
    name: "Forget Password",
    component: ForgetPassword,
    route: Route,
  },
  {
    path: "/auth/lock-screen",
    name: "Lock Screen",
    component: LockScreen,
    route: Route,
  },
  {
    path: "/auth/logout",
    name: "Logout",
    component: Logout,
    route: Route,
  },
  {
    path: "/auth/forgot-password/verify",
    name: "Verify password",
    component: VerifyForgetPassword,
    route: Route,
  },
  {
    path: "/auth/new-password",
    name: "New password",
    component: NewPassword,
    route: Route,
  },
]

// public routes
const otherPublicRoutes: RoutesProps[] = [
  {
    path: "/error-404",
    name: "Error - 404",
    component: Error404,
    route: Route,
  },
  {
    path: "/error-500",
    name: "Error - 500",
    component: Error500,
    route: Route,
  },
]

// flatten the list of all nested routes
const flattenRoutes = (routes: RoutesProps[]) => {
  let flatRoutes: RoutesProps[] = []

  routes = routes || []
  routes.forEach((item: RoutesProps) => {
    flatRoutes.push(item)

    if (typeof item.children !== "undefined") {
      flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)]
    }
  })
  return flatRoutes
}

// All routes
const authProtectedRoutes = [rootRoute, ...projectAppRoutes]
const publicRoutes = [...authRoutes, ...otherPublicRoutes]

const authProtectedFlattenRoutes = flattenRoutes([...authProtectedRoutes])
const publicProtectedFlattenRoutes = flattenRoutes([...publicRoutes])
export {
  authProtectedFlattenRoutes,
  authProtectedRoutes,
  publicProtectedFlattenRoutes,
  publicRoutes,
}
