import { useEffect } from "react";
import { getAccessToken } from "../utils/getAccessToken";
import { useHistory } from "react-router";

const ActionLogout = () => {
	const history = useHistory();
	const access_token = getAccessToken();

	useEffect(() => {
		if (
			!access_token &&
			!window.location.pathname.includes("auth/forgot-password/verify") &&
			!window.location.pathname.includes("auth/new-password")
		) {
			history.push("/auth/login");
		}
	}, [history, access_token]);

	return null;
};

export default ActionLogout;
