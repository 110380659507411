import { api, axiosClient } from "."
import { createAction } from "@reduxjs/toolkit"
import { IFilterPagination, IPagination } from "../models/paginations.model"
import { ITransaction } from "../models/transaction.model"

export const successToastAction = createAction<string>("toast/success")
export const failedToastAction = createAction<string>("toast/failed")

const transactionApi = api.injectEndpoints({
  endpoints: (build) => ({
    getTransactionByCustomer: build.query<
      {
        pagination: IPagination
        data: ITransaction[]
      },
      any
    >({
      query: ({ id, ...params }) => ({
        url: `/api/transactions/${id}/customer-detail`,
        method: "GET",
        params,
      }),
    }),
    getTransactionList: build.query<
      {
        data: ITransaction[]
        paginations: IPagination
      },
      IFilterPagination
    >({
      query: (params) => ({
        url: "api/transactions",
        method: "GET",
        params,
      }),
      providesTags: [{ type: "Transaction", id: "LIST" }],
    }),
    getTransactionDetail: build.query<
      {
        data: ITransaction
      },
      number
    >({
      query: (id: any) => ({
        url: `api/transactions/${id}`,
        method: "GET",
      }),
      providesTags: [{ type: "Transaction", id: "DETAIL" }],
    }),
    searchTransaction: build.query<
      {
        data: ITransaction[]
        pagination: IPagination
      },
      IFilterPagination
    >({
      query: (params) => ({
        url: "api/transactions",
        method: "GET",
        params,
      }),
    }),
    createTransaction: build.mutation<any, any>({
      query: (data) => ({
        url: "api/transactions",
        method: "POST",
        data,
      }),
      invalidatesTags: [{ type: "Transaction", id: "LIST" }],
    }),
    approveTransaction: build.mutation<any, any>({
      query: ({ id, ...data }) => ({
        url: `api/transactions/${id}`,
        method: "PUT",
        data,
      }),
      invalidatesTags: [{ type: "Transaction", id: "LIST" }],
    }),
  }),
})

export const approveTransaction = async (id: number) => {
  const res = await axiosClient.put(`/api/transactions/${id}`, {
    status: "hoàn thành",
  })
  return res.data
}

export const {
  useGetTransactionListQuery,
  useGetTransactionDetailQuery,
  useSearchTransactionQuery,
  useGetTransactionByCustomerQuery,
  useCreateTransactionMutation,
  useApproveTransactionMutation,
} = transactionApi
