import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IPayloadBulkEditStatus } from "./constants";

type Bulk = {
	loading: boolean;
	id: string;
	status: number;
	total: number;
	successIds?: number[];
	failedIds?: number[];
};

const deliverySlipSlice = createSlice({
	name: "deliverySlip",
	initialState: {
		bulks: [] as Bulk[],
	},
	reducers: {
		initBulkEditStatus: (
			state,
			action: PayloadAction<IPayloadBulkEditStatus>,
		) => {
			state.bulks = [
				...state.bulks,
				{
					loading: true,
					id: action.payload.id,
					status: action.payload.statusId,
					total: action.payload.deliveryBillIds.length,
				},
			];
		},
		onBulkEditStatusEnd: (
			state,
			action: PayloadAction<{
				successIds: number[];
				id: string;
				failedIds: number[];
			}>,
		) => {
			state.bulks = state.bulks.map((item) => {
				if (item.id === action.payload.id) {
					return {
						...item,
						loading: false,
						successIds: action.payload.successIds,
						failedIds: action.payload.failedIds,
					};
				}
				return item;
			});
		},
	},
});

export const { initBulkEditStatus, onBulkEditStatusEnd } =
	deliverySlipSlice.actions;

export default deliverySlipSlice.reducer;
