import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
	accountantApproveDeliveryBill,
	approveDeliveryBill,
	cancelDeliveryBill,
	failedDeliveryBill,
	finishDeliveryBill,
	packDeliveryBill,
	printDeliveryBill,
	skipApproveDeliveryBill,
} from "../../api/deliverySlipApi";
import { IPayloadBulkEditStatus } from "./constants";
import { initBulkEditStatus, onBulkEditStatusEnd } from "./reducers";
import { toast } from "react-toastify";

function* bulkEditStatus({
	payload: { id, statusId, deliveryBillIds },
}: {
	payload: IPayloadBulkEditStatus;
}) {
	const successIds: number[] = [];
	const failedIds: number[] = [];
	for (const deliveryBillId of deliveryBillIds) {
		try {
			// Call API
			switch (statusId) {
				case 1:
					yield call(approveDeliveryBill, deliveryBillId);
					break;
				case 2:
					yield call(accountantApproveDeliveryBill, deliveryBillId);
					break;
				case 3:
					yield call(skipApproveDeliveryBill, deliveryBillId);
					break;
				case 4:
					yield call(printDeliveryBill, deliveryBillId);
					break;
				case 5:
					yield call(packDeliveryBill, deliveryBillId);
					break;
				case 6:
					yield call(finishDeliveryBill, deliveryBillId);
					break;
				case 7:
					yield call(failedDeliveryBill, deliveryBillId);
					break;
				case 8:
					yield call(cancelDeliveryBill, deliveryBillId);
					break;
			}
			successIds.push(deliveryBillId);
		} catch (error) {
			failedIds.push(deliveryBillId);
		}
	}
	const successMessage = successIds
		.map((id) => `Phiếu #${id} đã được cập nhật thành công`)
		.join("\n");
	const failedMessage = failedIds
		.map((id) => `Phiếu #${id} cập nhật thất bại`)
		.join("\n");

	if (successIds.length > 0)
		toast.success(successMessage, {
			autoClose: 5000,
		});

	if (failedIds.length > 0)
		toast.error(failedMessage, {
			autoClose: 5000,
		});

	yield put(onBulkEditStatusEnd({ id, successIds, failedIds }));
}

function* watchBulkEditStatus() {
	yield takeEvery(initBulkEditStatus, bulkEditStatus);
}

export default function* deliverySlipSaga() {
	yield all([fork(watchBulkEditStatus)]);
}
