import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { IPayloadBulkApproveTransaction } from "./constants"

type Bulk = {
  loading: boolean
  id: string
  total: number
  successIds?: number[]
  failedIds?: number[]
}

const transactionSlice = createSlice({
  name: "transaction",
  initialState: {
    bulks: [] as Bulk[],
  },
  reducers: {
    initBulkApproveTransaction: (
      state,
      action: PayloadAction<IPayloadBulkApproveTransaction>
    ) => {
      state.bulks = [
        ...state.bulks,
        {
          loading: true,
          id: action.payload.id,
          total: action.payload.transactionIds.length,
        },
      ]
    },
    onBulkApproveTransactionEnd: (
      state,
      action: PayloadAction<{
        successIds: number[]
        id: string
        failedIds: number[]
      }>
    ) => {
      state.bulks = state.bulks.map((item) => {
        if (item.id === action.payload.id) {
          return {
            ...item,
            loading: false,
            successIds: action.payload.successIds,
            failedIds: action.payload.failedIds,
          }
        }
        return item
      })
    },
  },
})

export const { initBulkApproveTransaction, onBulkApproveTransactionEnd } =
  transactionSlice.actions

export default transactionSlice.reducer
