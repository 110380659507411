//@ts-nocheck
export interface IUserInfor {
	data: {
		email: string;
		fullname: string;
		id: number;
		type: string;
	};
	role: {
		id: number;
		actions: string[];
		isDelete: boolean;
		name: string;
		type: string;
	};
}

export const getUserInfor = () => {
	const userInfor: IUserInfor = JSON.parse(
		localStorage.getItem("user_infor"),
	);
	return userInfor ? userInfor : null;
};
