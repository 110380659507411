import { toast } from "react-toastify"
import { all, call, fork, put, takeEvery } from "redux-saga/effects"
import { approveDeliveryBill } from "../../api/deliverySlipApi"
import { IPayloadBulkApproveTransaction } from "./constants"
import {
  initBulkApproveTransaction,
  onBulkApproveTransactionEnd,
} from "./reducers"
import { approveTransaction } from "../../api/transactionApi"

function* bulkApproveTransaction({
  payload: { id, transactionIds },
}: {
  payload: IPayloadBulkApproveTransaction
}) {
  const successIds: number[] = []
  const failedIds: number[] = []
  for (const transactionId of transactionIds) {
    try {
      // Call API
      yield call(approveTransaction, transactionId)
      successIds.push(transactionId)
    } catch (error) {
      failedIds.push(transactionId)
    }
  }
  const successMessage = successIds
    .map((id) => `Phiếu #${id} đã được cập nhật thành công`)
    .join("\n")
  const failedMessage = failedIds
    .map((id) => `Phiếu #${id} cập nhật thất bại`)
    .join("\n")

  if (successIds.length > 0)
    toast.success(`${successMessage}`, {
      autoClose: 5000,
    })

  if (failedIds.length > 0)
    toast.error(`${failedMessage}`, {
      autoClose: 5000,
    })

  yield put(onBulkApproveTransactionEnd({ id, successIds, failedIds }))
}

function* watchBulkApproveTransaction() {
  yield takeEvery(initBulkApproveTransaction, bulkApproveTransaction)
}

export default function* deliverySlipSaga() {
  yield all([fork(watchBulkApproveTransaction)])
}
