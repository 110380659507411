import Routes from "./routes/Routes";
import Helmet from "react-helmet";
import "./assets/scss/Theme.scss";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useSelector } from "react-redux";
import { RootState } from "./redux/store";
import LoadingOverlay from "react-loading-overlay";

const App = () => {
	const { isLoading } = useSelector((state: RootState) => ({
		isLoading: state.Layout.isLoading,
	}));
	if (process.env.REACT_APP_THEME === "7days")
		return (
			<>
				<LoadingOverlay
					active={isLoading}
					spinner
					text="Đang cập nhật..."
				>
					<Helmet>
						<link
							rel="shortcut icon"
							href="/themes/7days/favicon.ico"
						/>
                          <link rel="manifest" href="/manifest_7days.json" />
						<meta name="theme-color" content="#001790" />
						<meta name="description" content="7Days Shipping" />
						<meta content="7Days Shipping" name="author" />
						<title>7Days Shipping</title>
					</Helmet>

					<Routes></Routes>
					<ToastContainer />
				</LoadingOverlay>
			</>
		);
	if (process.env.REACT_APP_THEME === "dpcargo")
		return (
			<>
				<LoadingOverlay
					active={isLoading}
					spinner
					text="Đang cập nhật..."
				>
					{" "}
                    
					<Helmet>
                        <link rel="manifest" href="/public/manifest_dpcaro.json" />
						<link
							rel="shortcut icon"
							href="/themes/dpcargo/favicon.ico"
						/>
						<meta name="theme-color" content="#CC3333" />
						<meta name="description" content="DP Cargo" />
						<meta content="DP Cargo" name="author" />
						<title>DP Cargo - Vận hành </title>
					</Helmet>
					<Routes></Routes>
					<ToastContainer />
				</LoadingOverlay>
			</>
		);
};

export default App;
