import { getUserInfor } from "./getUserInfor";
import moment from "moment-timezone";

export const converseDate = (data: string) => {
	const localTime = moment.utc(data).local().format("DD-MM-YYYY HH:mm:ss");
	return localTime;
};

export const converseDateTracking = (data: string) => {
	const date = new Date(data);

	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, "0"); // Tháng trong JavaScript bắt đầu từ 0
	const day = String(date.getDate()).padStart(2, "0");

	const formattedDate = `${day}-${month}-${year}`;
	return formattedDate;
};

export const converseDatePicker = (dateString: string) => {
	const format = new Date(dateString);
	return format;
};

export const converstISO = (originalDateString: string) => {
	// chuyển dạng Giờ Đông Dương về dạng ISO
	const dateTimeInGMT7 = new Date(originalDateString);
	const dateTimeInUTC = new Date(
		dateTimeInGMT7.getTime() - dateTimeInGMT7.getTimezoneOffset() * 60000,
	);

	return dateTimeInUTC.toISOString();
};

export const converstNextDay = (originalTimeString: string) => {
	// Thời điểm ban đầu ở múi giờ Đông Dương (GMT+7)
	const dateTimeInGMT7 = new Date(originalTimeString);
	const dateTimeInUTC = new Date(
		dateTimeInGMT7.getTime() - dateTimeInGMT7.getTimezoneOffset() * 60000,
	);
	const dateTimeInUTCNextDay = new Date(dateTimeInUTC.getTime() + 86400000);

	return dateTimeInUTCNextDay.toISOString();
};

export const roundToTwoDecimal = (num: number) => {
	var formattedNumber = parseFloat(num.toString()).toFixed(2);
	formattedNumber = parseFloat(formattedNumber).toLocaleString("en-US");
	if (formattedNumber.indexOf(".") === -1) {
		formattedNumber += ".00";
	} else if (formattedNumber.split(".")[1].length === 1) {
		formattedNumber += "0";
	}
	return formattedNumber;
};

export const checkDuplicateCode = (objects: any) => {
	let codeMap = new Map();

	for (let obj of objects) {
		if (codeMap.has(obj.code)) {
			return `Mã tracking ''${obj.code}'' bị trùng lặp.`;
		}
		codeMap.set(obj.code, true);
	}

	return null;
};

export const convertNumberToShortForm = (number: number) => {
	var isNegative = false;
	if (number < 0) {
		isNegative = true;
		number = Math.abs(number);
	}

	if (number >= 1e9) {
		return (isNegative ? "-" : "") + (number / 1e9).toFixed(1) + "B";
	} else if (number >= 1e6) {
		return (isNegative ? "-" : "") + (number / 1e6).toFixed(1) + "M";
	} else {
		return (isNegative ? "-" : "") + number.toString();
	}
};

export const converstFormatMoney = (money: number) => {
	return Math.ceil(money);
};

export const converstFormatWeight = (weight: number) => {
	return roundToTwoDecimal(weight);
};

export const checkPermision = (name: string) => {
	const userInfor = getUserInfor();
	if (userInfor?.role.actions.includes(name)) {
		return true;
	} else {
		return false;
	}
};

export const converstDateInput = (date: string) => {
	const localDateTime = moment.utc(date).local();
	const day = localDateTime.format("DD");
	const month = localDateTime.format("MM");
	const year = localDateTime.format("YYYY");
	const outputTime = `${year}-${month}-${day}`;
	return outputTime;
};

export const getDateInputForm = (date: string) => {
	const inputDate = new Date(date);
	const year = inputDate.getFullYear();
	const month = String(inputDate.getMonth() + 1).padStart(2, "0");
	const day = String(inputDate.getDate()).padStart(2, "0");
	return `${year}-${month}-${day}`;
};

export const convertStringToNumber = (stringNumber: string) => {
	if (!stringNumber) {
		return 0;
	} else {
		const stringWithoutCommas = stringNumber.replace(/,/g, "");
		const numberValue = parseFloat(stringWithoutCommas);

		return isNaN(numberValue) ? null : numberValue;
	}
};

export const getFirstDateOfMonth = (currentDate) => {
	const returnDate = new Date(currentDate);
	returnDate.setDate(1);
	returnDate.setHours(0);
	returnDate.setMinutes(0);
	returnDate.setSeconds(0);
	return returnDate;
};

export const getNextDateOfDate = (currentDate) => {
	const returnDate = new Date(currentDate);
	let nextDay = currentDate.getDate() + 1;

	returnDate.setDate(nextDay);
	return returnDate;
};

export const decimalToPercent = (decimal) => {
	// Chuyển đổi số thập phân sang phần trăm
	var percent = decimal * 100;
	// Làm tròn đến 2 chữ số sau dấu phẩy
	percent = Math.round(percent * 100) / 100;
	return percent + "%";
};
