import { all } from "redux-saga/effects"

import authSaga from "./auth/saga"
import layoutSaga from "./layout/saga"
import boxSaga from "./box/saga"
import deliverySlipSaga from "./deliveryBill/saga"
import transactionSaga from "./transaction/saga"

export default function* rootSaga() {
  yield all([
    authSaga(),
    layoutSaga(),
    boxSaga(),
    deliverySlipSaga(),
    transactionSaga(),
  ])
}
